import React, { useMemo } from 'react'
import propTypes from 'prop-types'
import { Box, Button, Divider, Grid, IconButton, SwipeableDrawer, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { formatPercentage } from '../../../utils/helpers'

function IngredientsListDrawer({ open, fullIngredients, ingredients, handleClose }) {
  const filteredIngredients = useMemo(() => {
    const filetedKeyIngredIents = ingredients.filter(ingredient => ingredient.is_top_4)
    if (!filetedKeyIngredIents.length) return ingredients.slice(0, 4)
    return filetedKeyIngredIents
  }, [ingredients])
  return (
    <SwipeableDrawer anchor="right" swipeAreaWidth={0} open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'relative',
          px: { xs: 2, md: 4 },
          py: 6,
          maxWidth: { xs: '100%', md: '435px' }
        }}
      >
        <IconButton sx={{ position: 'absolute', top: 32, right: 24 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" color="gray.elysian" sx={{ mb: 3 }}>
          Full Ingredients List
        </Typography>
        <Typography variant="title2" color="gray.elysian" component="div" sx={{ mb: 4 }}>
          {`Key ingredients (${ingredients.length})`}
        </Typography>
        <Grid container rowSpacing={5} columnSpacing={7} sx={{ mb: 6 }}>
          {filteredIngredients.map(ingredient => (
            <Grid item xs={6} key={ingredient.ingredient_name}>
              <Box
                component="img"
                src={ingredient.ingredient_img}
                sx={{ width: '84px', height: '84px', display: 'block', mb: 2 }}
              />
              <Typography variant="title2" color="gray.darkGray" component="div" gutterBottom>
                {ingredient.ingredient_name}
              </Typography>
              <Typography variant="footnote" color="gray.darkGray" component="div" gutterBottom>
                {ingredient.ingredient_description}
              </Typography>
              {ingredient.ingredient_percentage && (
                <Typography variant="title1" component="div" color="primary.main">
                  {formatPercentage(ingredient.ingredient_percentage)}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>

        <Typography variant="title2" color="gray.elysian" component="div" sx={{ mb: 1 }}>
          Full ingredients list:
        </Typography>
        <Box sx={{ mb: { xs: 1, md: 5 } }}>
          <Typography variant="body2" color="gray.darkGray">
            {fullIngredients.join(', ')}
          </Typography>
        </Box>
        <Divider
          sx={{
            display: { xs: 'block', md: 'none' },
            mb: 5,
            opacity: 0.2,
            mx: -2
          }}
        />
        <Box sx={{ textAlign: 'right', width: '100%' }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

IngredientsListDrawer.propTypes = {
  open: propTypes.bool.isRequired,
  fullIngredients: propTypes.array.isRequired,
  ingredients: propTypes.array.isRequired,
  handleClose: propTypes.func.isRequired
}

export default IngredientsListDrawer
